import axios from "axios"
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import grayArrow from '../../assets/gray-arrow-icon.png'
import { useAuth } from "../../contexts/AuthContext"
import { useBottomModal } from "../../contexts/BottomModalContext"
import { useSpecies } from "../../contexts/SpeciesContext"
import api from "../../services/api"
import { maskPoints } from "../../utils/mask"
import BalanceModalContent from "../BalanceModalContent"
import Loader from "../Loader"
import UserPointsBlockLink from "../UserPointsBlockLink"
import * as S from './style'

enum State {
  LOADING,
  READY
}

interface UserPointsDesktopState {
  currentState: State
  balance: any
}

function UserPointsDesktop () {
  const [isUserPointsDesktopExpanded, setIsUserPointsDesktopExpanded] = useState<boolean>(false)
  const { userCan, doLogout, user } = useAuth()
  const navigate = useNavigate()
  const { showBottomModal } = useBottomModal()
  const { specieSelected, isCatalogLeiteSelected } = useSpecies()
  const [state, setState] = useState<UserPointsDesktopState>({
      currentState: State.LOADING,
      balance: undefined
  })
  const [hasLeiteLojaBalance, setHasLeiteLojaBalance] = useState<boolean>(false)

  const handleUserPointsDesktopExpand = useCallback(() => {
      setIsUserPointsDesktopExpanded(prevState => !prevState);
  }, [])
  
  const handleDownloadHistory = useCallback(async (fileLink: string) => {
    const response = await axios.get(fileLink, { responseType: 'arraybuffer' })
    const blob = new Blob([response.data])
    const anchor = document.createElement("a");
    const url = URL.createObjectURL(blob);
    anchor.href = url;
    anchor.download = "historico-2021-2023.pdf";
    document.body.appendChild(anchor);
    anchor.click();
    setTimeout(function () {
        document.body.removeChild(anchor);
        window.URL.revokeObjectURL(url);
    }, 0);
  }, [])

  const handleShowModal = useCallback((component: ReactElement, event: any) => {
    event.stopPropagation()
    showBottomModal(component)
  }, [showBottomModal])

  const reinvestPoints = useMemo(() => {
      if (!specieSelected) return 0
      if (!state.balance) return 0
      return state.balance.categories[specieSelected.catalog]
  }, [specieSelected, state.balance])

  const reinvestPointsLeiteLoja = useMemo(() => {
    if (!specieSelected) return 0
    if (!state.balance) return 0
    return state.balance.categories[81554]
  }, [specieSelected, state.balance])

  const benefitsPoints = useMemo(() => {
      if (!state.balance) return 0
      return state.balance.categories[81544]
  }, [state.balance])

  const totalPoints = useMemo(() => {
      return reinvestPoints + benefitsPoints
  }, [benefitsPoints, reinvestPoints])

  useEffect(() => {
      (async () => {
          setState((state) => ({
              ...state,
              currentState: State.LOADING
          }))
          try {
              const response = await api.get('/balance')
              setState((state) => ({
                  ...state,
                  balance: response.data
              }))
          } catch (err) {

          }
          setState((state) => ({
              ...state,
              currentState: State.READY
          }))
      })()
  }, [])

  useEffect(() => {
    if (!specieSelected) return
    if (specieSelected.catalog === 81543 && specieSelected.childrens.length) setHasLeiteLojaBalance(true)
}, [specieSelected])

  if (state.currentState === State.LOADING) return <Loader />

    return <>
        <S.UserPointsDesktop>
            <div className='user-name-container'>
                Bem-vindo!
                <span>{user?.name.split(' ')[0]}</span>
            </div>
            <S.UserPoints>
                <div className='background'></div>
                <S.UserPointsTotalContainer>
                    <div className='total-points'>
                        Você tem no total
                        <div className='total-points-number'>{maskPoints(totalPoints)} pts</div>
                    </div>
                    <S.ToggleUserPoints onClick={handleUserPointsDesktopExpand}>
                        <S.ToggleUserPointsArrow $isToggled={isUserPointsDesktopExpanded} >
                            <img src={grayArrow} alt="Ícone de seta, indicando um link que pode ser clicado" />
                        </S.ToggleUserPointsArrow>
                    </S.ToggleUserPoints>
                </S.UserPointsTotalContainer>
                <S.UserPointsDesktopMenu $isVisible={isUserPointsDesktopExpanded}>
                    {userCan('update:points') && <S.UserPointsDesktopMenuDetails>
                    {isCatalogLeiteSelected ? <>
                            <UserPointsBlockLink title='Conta Reinvestimento Leite Fábrica' isVisible={isUserPointsDesktopExpanded} points={reinvestPoints} />
                            {hasLeiteLojaBalance && <UserPointsBlockLink title='Conta Reinvestimento Leite Loja' isVisible={isUserPointsDesktopExpanded} points={reinvestPointsLeiteLoja} />}
                        </> : 
                            <UserPointsBlockLink title='Conta Reinvestimento' isVisible={isUserPointsDesktopExpanded} points={reinvestPoints} />
                    }
                    <UserPointsBlockLink title='Conta Benefícios' isVisible={isUserPointsDesktopExpanded} points={benefitsPoints} />
                        {userCan('read:button-troca-pontos') && <S.UserPointsButtons>
                            <button className='button-green' onClick={() => navigate('/transfira-seus-pontos')}>Transfira seus pontos</button>
                            <button className='button-outlined' onClick={(event) => handleShowModal(<BalanceModalContent />, event)}>Entenda seu saldo</button>
                        </S.UserPointsButtons>
                        }
                    </S.UserPointsDesktopMenuDetails>}
                    <S.UserPointsDesktopMenuLinks>
                        <ul>
                            <Link to="/como-funciona" ><li>Como Funciona</li></Link>
                            <Link to="/meus-dados"><li>Meus Dados</li></Link>
                            <li onClick={() => handleDownloadHistory(user.historico)}>Baixar Histórico 2021 a 2023</li>
                            <Link to="/fale-conosco-logged"><li>Fale Conosco</li></Link>
                            <Link to="/perguntas-frequentes"><li>Perguntas Frequentes</li></Link>
                            <Link to="/regulamento"><li>Regulamento</li></Link>
                            <li onClick={() => doLogout()}>Sair</li>
                        </ul>
                    </S.UserPointsDesktopMenuLinks>
                </S.UserPointsDesktopMenu>
            </S.UserPoints>
        </S.UserPointsDesktop>
    </>
}

export default UserPointsDesktop
