
import axios from 'axios';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useBottomModal } from '../../contexts/BottomModalContext';
import { useSideMenu } from '../../contexts/SideMenuContext';
import { useSpecies } from '../../contexts/SpeciesContext';
import api from '../../services/api';
import BackButton from '../Header/BackButton';
import RegulamentoContent from '../RegulamentoContent';
import profileImage from './assets/profile-icon.png';
import * as S from './style';

function SideMenu () {
    const { isSideMenuOpen, toggleSideMenu } = useSideMenu()
    const { showBottomModal } = useBottomModal()
    const { doLogout, user, userCan } = useAuth()
    const { specieSelected, userHasGoldBox } = useSpecies()

    const handleOpenBottomModal = useCallback((element: ReactNode) => {
        toggleSideMenu()
        showBottomModal(element)

    }, [showBottomModal, toggleSideMenu])

    const handleLogout = useCallback(() => {
        toggleSideMenu()
        doLogout()
    }, [doLogout, toggleSideMenu])

    const [link, setLink] = useState<string>('')
    const handleRedirect = useCallback(async () => {
        if (!specieSelected) return
        if (!user) return
        const response = await api.get(`/cargill-catalogo/${specieSelected.catalog}`)
        setLink(response.data.url)
    }, [specieSelected, user])

    const [linkBene, setLinkBene] = useState<string>('')
    const handleRedirectBeneficios = useCallback(async () => {
        if (!user) return
        const response = await api.get(`/cargill-catalogo/81544`)
        setLinkBene(response.data.url)
    }, [user])

    const [goldBoxLink, setGoldBoxLink] = useState<string>('')
    useEffect(() => {
        (async () => {
            if (!specieSelected) return
            const response = await api.get(`/cargill-gold-box/${specieSelected.catalog}`)
            setGoldBoxLink(response.data.url)
        })()
    }, [specieSelected]);

    const handleDownloadHistory = useCallback(async (fileLink: string) => {
        const response = await axios.get(fileLink, { responseType: 'arraybuffer' })
        const blob = new Blob([response.data])
        const anchor = document.createElement("a");
        const url = URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = "historico-2021-2023.pdf";
        document.body.appendChild(anchor);
        anchor.click();
        setTimeout(function () {
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
        }, 0);
    }, [])

    useEffect(() => {
        handleRedirect()
        handleRedirectBeneficios()
    }, [handleRedirect, handleRedirectBeneficios])

    return <S.SideMenuBackdrop $isSideMenuOpen={isSideMenuOpen ? 'true' : 'false'} >
        <S.SideMenu>
            <S.SideMenuHeader >
                <BackButton onClick={toggleSideMenu} />
                <S.SideMenuProfilePic >
                    <img src={profileImage} alt="Imagem de perfil do usuário" />
                </S.SideMenuProfilePic>
            </S.SideMenuHeader>
            <S.SideMenuNavigation>
                <S.SideMenuUserName >
                    Bem-vindo!
                    <span>{user?.name.split(' ')[0]}</span>
                </S.SideMenuUserName>
                <ul>
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/inicio" >
                        Início
                    </S.SideMenuNavigationLinkOption >
                    {userCan('read:category') && <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/como-funciona" >
                        Sua Categoria
                    </S.SideMenuNavigationLinkOption >}
                    {userCan('read:wallet') && <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/carteira" >
                        Sua Carteira
                    </S.SideMenuNavigationLinkOption >}
                    {userHasGoldBox && <S.SideMenuNavigationLinkOptionAnchor onClick={toggleSideMenu} href={goldBoxLink} target='_blank' >
                        Gold Box
                    </S.SideMenuNavigationLinkOptionAnchor >}
                    {userCan('read:reinvest-catalog') && <S.SideMenuNavigationLinkOptionAnchor href={link} target='_blank'>
                        Catálogo Reinvestimento
                    </S.SideMenuNavigationLinkOptionAnchor >}
                    {userCan('read:benefits-catalog') && <S.SideMenuNavigationLinkOptionAnchor href={linkBene} target="_blank" >
                        Catálogo Benefício
                    </S.SideMenuNavigationLinkOptionAnchor>}
                    {userCan('update:points') && <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/transfira-seus-pontos" >
                        Transfira seu Pontos
                    </S.SideMenuNavigationLinkOption >}
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/blog" >
                        Blog
                    </S.SideMenuNavigationLinkOption >
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/como-funciona" >
                        Como Funciona
                    </S.SideMenuNavigationLinkOption >
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/meus-dados" >
                        Meus dados
                    </S.SideMenuNavigationLinkOption >
                    {user?.historico && <S.SideMenuNavigationOption onClick={() => handleDownloadHistory(user.historico)}>
                        Baixar Histórico 2021 a 2023
                    </S.SideMenuNavigationOption>}
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/fale-conosco-logged" $isDarkBg={true} >
                        Fale Conosco
                    </S.SideMenuNavigationLinkOption >
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/perguntas-frequentes" $isDarkBg={true} >
                        Perguntas Frequentes
                    </S.SideMenuNavigationLinkOption >
                    <S.SideMenuNavigationOption onClick={() => handleOpenBottomModal(<RegulamentoContent />)} $isDarkBg={true} >
                        Regulamento
                    </S.SideMenuNavigationOption >
                    <S.SideMenuNavigationLinkOption onClick={toggleSideMenu} to="/adicionar-atalho" $isDarkBg={true} >
                        Atalho no celular
                    </S.SideMenuNavigationLinkOption>
                    <S.SideMenuNavigationOption onClick={handleLogout} $isDarkBg={true} >
                        Sair
                    </S.SideMenuNavigationOption >
                </ul>
            </S.SideMenuNavigation>
        </S.SideMenu>
    </S.SideMenuBackdrop>
}

export default SideMenu